import { countries } from "countries-list";

export const countryList = Object.keys(countries).map((countryCode) => {
  const e = countries[countryCode];
  return {
    id: e.name,
    name: `${e.emoji} (+${e.phone}) ${e.native}`,
    phone: `+${e.phone}`,
    countryCode,
  };
});
