import axios from "axios";
import { useToast } from "vue-toastification";
import {
  API_URL,
  AUTH_TOKENS_KEY,
  AUTH_TOKENS_HEADER,
  USER_INFO_KEY,
} from "./config";
import { useUserStore } from "@/stores/user";
import { countryList } from "./country";
import router from "../router";

const MAX_REFRESH_REQUESTS = 5;
let refreshCount = 0;

const toast = useToast();

const fetcher = axios.create();
fetcher.defaults.baseURL = API_URL;

const api = {
  tokens: {},
  loginTg(data) {
    return fetcher.post("/loginTG", data);
  },
  loginMail(data) {
    return fetcher.post("/login", data);
  },
  loginPhone(data) {
    return fetcher.post("/loginPhone", data);
  },
  refreshToken() {
    if (refreshCount === MAX_REFRESH_REQUESTS || !this.tokens.refreshToken) {
      throw new Error("Refresh token problem");
    }
    refreshCount += 1;
    console.log({ refreshCount });
    return fetcher.post("/loginRefresh", {
      refreshToken: this.tokens.refreshToken,
    });
  },
  getMe() {
    return fetcher.get("/me").then((response) => {
      useUserStore().setUser(response.data);
      return response;
    });
  },
  updateMe(data) {
    if (Object.keys(data).length == 0) {
      throw new Error("Empty form");
    }
    return fetcher.put("/me", data);
  },
  sendTfa(data) {
    return fetcher.post("/2fa", data);
  },
  async viewerLocation() {
    try {
      const { data } = await fetcher.get("/viewerLocation");
      const item = countryList.find((e) => e.countryCode === data.countryCode);
      return item || {};
    } catch (err) {
      console.error(err);
      return {};
    }
  },
  logout(callback) {
    this.tokens = {};
    localStorage.removeItem(AUTH_TOKENS_KEY);
    localStorage.removeItem(USER_INFO_KEY);
    delete fetcher.defaults.headers.common[AUTH_TOKENS_HEADER];
    useUserStore().clearStore();
    if (callback) {
      callback();
    }
    return true;
  },
  getTokensFromLocalStorage() {
    try {
      const keys = JSON.parse(localStorage.getItem(AUTH_TOKENS_KEY));
      api.setTokens(keys);
    } catch (error) {
      console.error(error);
    }
  },
  setTokens(data) {
    if (data.token && data.refreshToken) {
      refreshCount = 5;
      localStorage.setItem(AUTH_TOKENS_KEY, JSON.stringify(data));
      fetcher.defaults.headers.common[AUTH_TOKENS_HEADER] = data.token;
      this.tokens = data;

      api.getMe();
      api.fetchBots();

      return true;
    }
    return false;
  },
  createBot(botId, apiToken) {
    const url = botId ? `/bots/${botId}` : `/bots`;
    const method = botId ? "PUT" : "POST";
    return fetcher({ url, method, data: { apiToken } }).then((response) => {
      useUserStore().setBotDetails(botId, response.data);
      return response;
    });
  },
  updateBot(botId, data) {
    delete data.apiToken;
    return fetcher.put(`/bots/${botId}`, data);
  },
  uploadBotFile(botId, fileName, fileBase64) {
    const url = `/bots/${botId}/document`;
    const method = "POST";
    return fetcher({
      url,
      method,
      data: {
        fileName,
        fileBase64,
      },
    }).then((response) => {
      return response;
    });
  },
  fetchBots() {
    return fetcher.get("/bots").then((response) => {
      useUserStore().setBots(response.data);
      if (response.data.length == 0) {
        router.push({ name: "BotsCreate" });
      }
      return response;
    });
  },
  fetchBotsById(botId) {
    return fetcher.get(`/bots/${botId}`);
  },
  fetchBotAnalytics(botId, params = {}) {
    return fetcher({
      url: `/bots/${botId}/analytics`,
      method: "GET",
      params: params.endDate && params.startDate ? params : {},
    });
  },
  fetchBotOrderHistoryCSV(botId, params = {}) {
    return fetcher({
      url: `/bots/${botId}/order-history`,
      method: "GET",
      params: params.endDate && params.startDate ? params : {},
    });
  },
  deleteBot(botId, code2fa) {
    return fetcher
      .delete(`/bots/${botId}?code2fa=${code2fa}`)
      .then((response) => {
        useUserStore().removeBot(botId);
        return response;
      });
  },
  addBotManager(botId, tgUsername) {
    return fetcher
      .post(`/bots/${botId}/manager`, { tgUsername: tgUsername })
      .then((response) => {
        useUserStore().setBotDetails(botId, response.data);
        return response;
      });
  },
  deleteBotManager(botId, managerId) {
    return fetcher
      .delete(`/bots/${botId}/manager/${managerId}`)
      .then((response) => {
        useUserStore().setBotDetails(botId, response.data);
        return response;
      });
  },
  createSupportRequest(data) {
    return fetcher.post("/support/requests", data);
  },
};

fetcher.interceptors.response.use(
  (res) => {
    api.setTokens(res.data);
    return res;
  },
  async (err) => {
    const status = err?.response?.status || 500;
    console.error(err);
    if (status == 405) {
      try {
        await api.refreshToken();
        return fetcher.request(err.config);
      } catch (err) {
        api.logout();
        return Promise.reject(err);
      }
    }
    const toastMsg = err?.response?.data?.error || err.message;
    toast.error(toastMsg);
    return Promise.reject({ ...err, toastMsg });
  }
);

api.getTokensFromLocalStorage();

export { fetcher, api };

export default api;
