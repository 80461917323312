<script>
import { APP_NAME } from "@/modules/config";

export default {
  name: "App",
  watch: {
    $route: {
      handler(to) {
        if (to?.meta?.name) {
          document.title = `${this.$t(to?.meta?.name)} | ${APP_NAME}`;
          return;
        }
        document.title = `${APP_NAME}`;
      },
      immediate: true,
      deep: true,
    },
  },
};
</script>

<template>
  <RouterView />
</template>
