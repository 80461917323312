<script>
import LangSelector from "../LangSelector.vue";
import Logo from "@/assets/graphic/logo.svg";

import AuthGr0 from "@/assets/graphic/auth-gr-0.svg";
import AuthGr1 from "@/assets/graphic/auth-gr-1.svg";
import AuthGr2 from "@/assets/graphic/auth-gr-2.svg";
import AuthGr3 from "@/assets/graphic/auth-gr-3.svg";
import AuthGr4 from "@/assets/graphic/auth-gr-4.svg";

export default {
  name: "AuthLayout",
  components: {
    LangSelector,
    Logo,
    AuthGr0,
    AuthGr1,
    AuthGr2,
    AuthGr3,
    AuthGr4,
  },
};
</script>

<template>
  <div :class="$style.auth_wrapper" class="row flex-grow">
    <div class="col-xs-12 col-md-6 hide-xs" :class="$style.left_side">
      <div :class="$style.left_side_gr0">
        <AuthGr0 :style="{ animation: 'scale 800ms ease-in-out' }" />
      </div>
      <div :class="$style.left_side_gr1">
        <AuthGr1 :style="{ animation: 'scale 1500ms ease-in-out' }" />
      </div>
      <div :class="$style.left_side_gr2">
        <AuthGr2 :style="{ animation: 'scale 1200ms ease-in-out' }" />
      </div>
      <div :class="$style.left_side_gr3">
        <AuthGr3 :style="{ animation: 'scale 1800ms ease-in-out' }" />
      </div>
      <div :class="$style.left_side_gr4">
        <AuthGr4 :style="{ animation: 'scale 1200ms ease-in-out' }" />
      </div>
    </div>
    <div :class="$style.right_side" class="col-xs-12 col-md-6">
      <div>
        <router-link to="/sign-in" class="m4 base-link text-center">
          <logo height="80" />
        </router-link>
      </div>
      <div :class="$style.right_side__content">
        <router-view v-slot="{ Component }">
          <transition name="auth-route">
            <component :is="Component" />
          </transition>
        </router-view>
      </div>
      <div class="full-width">
        <div class="p2">
          <div class="divider-h mt1 mb1" />
          <lang-selector />
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="scss" module>
.auth_wrapper {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  overflow: hidden;
  background: #e8ecf1;
}

.left_side {
  position: relative;

  svg {
    width: 100%;
  }

  &_gr0 {
    width: 60%;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 2;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  &_gr1 {
    width: 54%;
    position: absolute;
    bottom: 0%;
    right: 0%;
    z-index: 2;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  &_gr2 {
    width: 30%;
    position: absolute;
    top: 5%;
    left: 8%;
    z-index: 2;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  &_gr3 {
    width: 20%;
    position: absolute;
    top: 25%;
    right: 11%;
    z-index: 2;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  &_gr4 {
    width: 14%;
    position: absolute;
    bottom: 19%;
    left: 20%;
    z-index: 2;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

.right_side {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  background: $white;
  position: relative;
  animation: slide-left 300ms ease-in-out;

  &__content {
    position: relative;
    overflow: hidden;
    display: flex;
    flex: 1;
    align-items: center;
    justify-content: center;
    width: 100%;

    & > div {
      flex: 1;
      display: flex;
      flex-direction: column;
      max-width: calc(352px + 2rem);
      width: calc(100% - 2rem);
    }
  }

  h1 {
    text-align: center;
    font-style: normal;
    font-weight: 600;
    font-size: 32px;
    line-height: 24px;
    letter-spacing: 0.38px;
    color: $text-color;
  }

  button {
    flex: 1;
  }
}
</style>
