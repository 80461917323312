<script>
import Logo from "@/assets/graphic/logo.svg";
import { useUserStore } from "@/stores/user";
import { mapState } from "pinia";
import BaseButton from "./BaseButton.vue";
import CodeIcon from "@/assets/graphic/code.svg";
import ChartIcon from "@/assets/graphic/chart-square.svg";
import FavoriteIcon from "@/assets/graphic/favorite-chart.svg";
import MenuIcon from "@/assets/graphic/menu.svg";
import CloseIcon from "@/assets/graphic/close-circle.svg";
import LogoutIcon from "@/assets/graphic/logout.svg";
import EditIcon from "@/assets/graphic/edit-2.svg";
import BaseIconButton from "./BaseIconButton.vue";
import BaseAvatar from "./BaseAvatar.vue";
import BotCard from "./BotCard.vue";

export default {
  name: "AccountSidebar",
  props: {
    active: Boolean,
  },
  components: {
    Logo,
    BaseButton,
    CodeIcon,
    ChartIcon,
    FavoriteIcon,
    MenuIcon,
    CloseIcon,
    BaseIconButton,
    BaseAvatar,
    LogoutIcon,
    EditIcon,
    BotCard,
  },
  emits: ["close"],
  computed: {
    ...mapState(useUserStore, [
      "USER",
      "IS_LOGGED_IN",
      "BOTS",
      "ACTIVE_BOT_ID",
    ]),
  },
  methods: {},
};
</script>

<template>
  <div :class="[$style.account_sidebar, { [$style.active]: active }]">
    <div :class="$style.account_sidebar__top">
      <router-link to="/account" :class="$style.account_sidebar__logo">
        <logo />
      </router-link>
      <base-icon-button
        :class="[$style.account_sidebar__close, $style.animation_scale]"
        color="gray"
        size="small"
        @click="$emit('close')"
      >
        <close-icon width="20" height="20" />
      </base-icon-button>
    </div>
    <div
      v-if="IS_LOGGED_IN"
      :class="$style.account_sidebar__user"
      class="mt2 mb2"
    >
      <base-avatar
        :class="$style.animation_scale"
        :style="{ animationDuration: `1s` }"
        class="mr2"
        :url="USER?.profilePhotoUrl"
      />
      <div :class="$style.account_sidebar__user__text">
        <div :class="$style.account_sidebar__user__name">
          {{ USER.firstName || "" }} {{ USER.lastName || "" }}
        </div>
        <div :class="$style.account_sidebar__user__action">
          <base-button
            :active="$route.name === 'AccountProfile'"
            :href="{ name: 'AccountProfile' }"
            color="transparent"
            class="flex-grow start-xs col-lg-8 pl1 pr3"
            style="float: left"
            text-grow
            small
          >
            <template #icon-left><edit-icon class="mr1" /></template>
            {{ $t("sidebar.editProfile") }}
          </base-button>
          <base-button
            :class="$style.animation_fade"
            :style="{ animationDuration: `2s` }"
            color="transparent"
            small
            class="start-xs mt0 mb2 col-lg-5 col-lg-offset-6 pl1 pr0"
            href="/logout"
          >
            <template #icon-left><logout-icon class="mr1" /></template>
            {{ $t("ui.logout") }}
          </base-button>
        </div>
      </div>
    </div>
    <div class="divider-h" />
    <div
      v-if="ACTIVE_BOT_ID"
      :class="[$style.account_sidebar__title, $style.account_sidebar__padding]"
    >
      {{ $t("sidebar.shortcuts") }}
      <span style="font-weight: normal"
        >@{{
          BOTS.filter((obj) => {
            return obj.botId === ACTIVE_BOT_ID;
          })[0].tgUsername
        }}</span
      >
    </div>
    <div
      v-if="ACTIVE_BOT_ID"
      :class="$style.account_sidebar__shortcuts"
      class="row m0"
    >
      <div class="col-xs-6 p0 pr1 mb1">
        <base-button
          :class="$style.animation_scale"
          :style="{ animationDuration: `1.2s` }"
          :active="$route.name === 'BotsAnalytics'"
          :href="{ name: 'BotsAnalytics', params: { botId: ACTIVE_BOT_ID } }"
          small
          text-grow
          color="violet"
          class="start-xs"
        >
          <template #icon-left> <chart-icon class="mr1" /> </template>
          {{ $t("sidebar.analytics") }}
        </base-button>
      </div>
      <div class="col-xs-6 p0 pr1 mb1">
        <base-button
          :class="$style.animation_scale"
          :style="{ animationDuration: `1s` }"
          :active="$route.name === 'BotsEditor'"
          :href="{ name: 'BotsEditor', params: { botId: ACTIVE_BOT_ID } }"
          small
          text-grow
          class="start-xs"
        >
          <template #icon-left> <code-icon class="mr1" /> </template>
          {{ $t("sidebar.botSetting") }}
        </base-button>
      </div>
      <div class="col-xs-6 p0 pr1 mb1">
        <base-button
          :class="$style.animation_scale"
          :style="{ animationDuration: `1.3s` }"
          :active="$route.name === 'BotsInvoices'"
          :href="{ name: 'BotsInvoices', params: { botId: ACTIVE_BOT_ID } }"
          small
          text-grow
          color="red"
          class="start-xs"
        >
          <template #icon-left> <favorite-icon class="mr1" /> </template>
          {{ $t("sidebar.invoices") }}
        </base-button>
      </div>
      <div class="col-xs-6 p0 pr1 mb1">
        <base-button
          :class="$style.animation_scale"
          :style="{ animationDuration: `1.4s` }"
          small
          text-grow
          color="orange"
          class="start-xs"
        >
          <template #icon-left>
            <menu-icon class="mr1" />
          </template>
          {{ $t("sidebar.textMessages") }}
        </base-button>
      </div>
    </div>

    <div
      :class="[$style.account_sidebar__title, $style.account_sidebar__padding]"
    >
      {{ $t("sidebar.companyList") }}
    </div>

    <base-button
      :class="$style.animation_scale"
      :style="{ animationDuration: `1s` }"
      :active="$route.name === 'BotsCreate'"
      class="text-center"
      text-grow
      small
      href="/account/bots/create"
      >{{ $t("ui.botCreate") }}</base-button
    >
    <div
      v-if="IS_LOGGED_IN && BOTS && BOTS.length"
      :class="[$style.account_sidebar__padding]"
      class="row m0"
    >
      <div
        v-for="(bot, index) in BOTS"
        :key="bot.id"
        :class="{
          pr1: index % 2 == 0 && BOTS.length > 1,
        }"
        class="p0 mb1 col-xs-6"
      >
        <bot-card
          :class="$style.animation_scale"
          :style="{ animationDuration: `${300 * index + 1000}ms` }"
          :active="ACTIVE_BOT_ID === bot.botId"
          v-bind="bot"
          link
        />
      </div>
    </div>
  </div>
</template>

<style lang="scss" module>
@import "@/assets/keyframes.scss";
.account_sidebar {
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 304px;
  padding: 24px 48px;
  overflow-x: hidden;
  overflow-y: auto;
  background: $account-sidebar-background;
  border: 1px solid $account-sidebar-border;
  animation: slide-right 800ms ease-in-out;
  z-index: 20;
  position: fixed;
  left: 0;
  top: 0;
  bottom: 0;

  &__close {
    visibility: hidden;
    padding: 10px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  &__title {
    font-style: normal;
    font-weight: 700;
    font-size: 18px;
    line-height: 130%;
    color: $text-color;
  }

  &__padding {
    padding: 18px 0;
  }

  &__top {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 20px;
  }

  &__logo {
    display: flex;
    align-items: center;
    justify-content: start;

    svg {
      height: 43px;
    }
  }

  &__user {
    display: flex;
    flex-direction: row;

    &__text {
      display: flex;
      flex-direction: column;
      justify-content: center;
      flex: 1;
    }

    &__name {
      font-weight: 700;
      font-size: 20px;
      line-height: 130%;
      color: #70d974;
    }
  }

  &__shortcuts {
    & > div {
      display: flex;

      & > * {
        flex: 1;
      }
    }
  }

  .animation_scale {
    animation-duration: 1s;
    animation-timing-function: ease-in-out;
    animation-delay: 0s;
    animation-iteration-count: 1;
    animation-direction: normal;
    animation-fill-mode: none;
    animation-play-state: running;
    animation-name: scale;
  }

  .animation_fade {
    animation-duration: 1s;
    animation-timing-function: ease-in-out;
    animation-delay: 0s;
    animation-iteration-count: 1;
    animation-direction: normal;
    animation-fill-mode: none;
    animation-play-state: running;
    animation-name: fade;
  }

  @media (max-width: 1024px) {
    max-width: 80%;
    position: fixed;
    z-index: 23;
    right: 0;
    bottom: 0;
    top: 0;
    left: unset;
    transform: translate(200%);
    transition: all 800ms;
    padding: 16px;
    animation: none;

    .animation_scale,
    .animation_fade {
      animation: none;
    }

    &.active {
      transform: translate(0);

      .animation_scale {
        animation-duration: 1s;
        animation-timing-function: ease-in-out;
        animation-delay: 0s;
        animation-iteration-count: 1;
        animation-direction: normal;
        animation-fill-mode: none;
        animation-play-state: running;
        animation-name: scale;
      }

      .animation_fade {
        animation-duration: 1s;
        animation-timing-function: ease-in-out;
        animation-delay: 0s;
        animation-iteration-count: 1;
        animation-direction: normal;
        animation-fill-mode: none;
        animation-play-state: running;
        animation-name: fade;
      }
    }

    &__close {
      visibility: visible;
    }
  }
}
</style>
