<script>
import AccountSidebar from "@/components/AccountSidebar.vue";
import AccountHeader from "@/components/AccountHeader.vue";
import LangSelector from "../LangSelector.vue";
import { useUserStore } from "@/stores/user";

export default {
  name: "AccountLayout",
  components: { AccountSidebar, AccountHeader, LangSelector },
  data() {
    return {
      accountSidebar: false,
    };
  },
  computed: {
    IS_LOGGED_IN: () => useUserStore().IS_LOGGED_IN,
  },
  watch: {
    "$route.path": {
      handler(o1, o2) {
        if (o1 !== o2) {
          this.accountSidebar = false;
        }
      },
      immediate: true,
    },
  },
};
</script>

<template>
  <div :class="$style.account_layout">
    <!-- <transition name="slide-left"> -->
    <account-sidebar
      v-if="IS_LOGGED_IN"
      :class="$style.account_layout__sidebar"
      :active="accountSidebar"
      @close="accountSidebar = false"
    />
    <!-- </transition> -->
    <div
      :class="[
        $style.account_layout__content,
        { [$style.no_sidebar]: !IS_LOGGED_IN },
      ]"
    >
      <account-header
        :class="$style.account_layout__header"
        @openSidebar="accountSidebar = true"
      />
      <router-view
        v-slot="{ Component }"
        :class="$style.account_layout__page"
        @click="accountSidebar = false"
      >
        <transition name="account-route">
          <component class="flex-grow" :is="Component" />
        </transition>
      </router-view>
      <div
        :style="{ animation: 'slide-up 800ms ease-in-out' }"
        @click="accountSidebar = false"
      >
        <div class="divider-h mr2 ml2" />
        <div :class="$style.account_layout__footer">
          <lang-selector />
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="scss" module>
.account_layout {
  display: flex;
  min-height: 100vh;
  width: 100%;
  max-width: 100vw;

  &__content {
    flex: 1;
    display: flex;
    flex-direction: column;
    max-width: calc(100vw - 402px);
    margin-left: 402px;

    &.no_sidebar {
      margin-left: 0;
      max-width: 100vw;
    }
  }

  &__page {
    flex: 1;
    padding: 24px 32px;

    h1 {
      font-weight: 600;
      font-size: 20px;
      line-height: 24px;
      letter-spacing: 0.38px;
      color: $text-color;
    }
  }

  &__footer {
    padding: 24px 32px;
  }

  @media (max-width: 1024px) {
    &__content {
      max-width: 100vw;
      margin-left: 0;
    }

    &__page {
      flex: 1;
      padding: 24px 16px;
    }

    &__footer {
      padding: 24px 16px;
    }
  }
}
</style>
