import { defineStore } from "pinia";
import { USER_ACTIVE_BOT_ID_KEY, USER_INFO_KEY } from "../modules/config";

let user = null;
let activeBotId = null;

try {
  user = JSON.parse(localStorage.getItem(USER_INFO_KEY));
  activeBotId = localStorage.getItem(USER_ACTIVE_BOT_ID_KEY) || null;
} catch (err) {
  console.error(err);
}

export const useUserStore = defineStore({
  id: "user",
  state: () => ({
    user,
    activeBotId,
    bots: [],
    botsDetails: {},
  }),
  getters: {
    USER: (state) => state.user,
    BOTS: (state) => state.bots,
    BOT_CURRENT: (state) => {
      let botId = state.bots.length
        ? state.activeBotId || state.bots[0].botId
        : null;
      let searchResults = state.bots.filter((obj) => {
        return obj.botId === botId;
      });
      return searchResults.length ? searchResults[0] : {};
    },
    IS_LOGGED_IN: (state) => state.user != null,
    BOT_DETAILS: (state) => (botId) =>
      botId in state.botsDetails ? state.botsDetails[botId] : {},
    ACTIVE_BOT_ID: (state) =>
      state.bots.length ? state.activeBotId || state.bots[0].botId : null,
    BOT_HAS_ACTIVE_STATUS: (state) => (id) => {
      const bot = state.botsDetails[id] || {};
      return ["operational"].indexOf(bot.status) >= 0;
    },
  },
  actions: {
    setUser(user) {
      this.user = user;
      localStorage.setItem(USER_INFO_KEY, JSON.stringify(user));
    },
    setBots(bots) {
      this.bots = bots;
      const active = this.bots.find((e) => e.botId === activeBotId);
      if (!active) {
        this.activeBotId = null;
        localStorage.removeItem(USER_ACTIVE_BOT_ID_KEY);
      }
    },
    setBotDetails(botId, value) {
      this.botsDetails[botId] = value;
    },
    setActiveBotId(botId) {
      this.activeBotId = botId;
      localStorage.setItem(USER_ACTIVE_BOT_ID_KEY, botId);
    },
    removeBot(botId) {
      localStorage.removeItem(USER_ACTIVE_BOT_ID_KEY);
      delete this.botsDetails[botId];
      this.bots = this.bots.filter((o) => o.botId !== botId);
    },
    clearStore() {
      this.user = null;
      this.bots = [];
      setTimeout(() => {
        this.botsDetails = {};
      }, 0);
    },
  },
});
