{
    "form.email.placeholder": "Enter your email",
    "form.email.label": "Email",
    "form.password.placeholder": "Your password",
    "form.password.label": "Password",
    "btn.signIn": "Sign in",
    "page.title.signIn": "Sign in",
    "form.forgotPasswordQuestion": "Forgot password?",
    "form.signInTelegram": "Sign in by telegram",
    "form.signIn": "Sign in",
    "page.title.signInTelegram": "Sign in by telegram",
    "form.signInMail": "Sign in by mail",
    "form.signInPhone": "Sign in by phone",
    "form.country.placeholder": "Select your country from list",
    "form.country.label": "Country",
    "form.phone.placeholder": "Your phone number",
    "form.phone.label": "Phone number",
    "form.rememberMe": "Remember me",
    "ui.language": "Language:",
    "locale.name.en": "🇺🇸 English",
    "locale.name.uk": "🇺🇦 Українська",
    "locale.name.fr": "🇫🇷 Français",
    "locale.name.ru": "🇷🇺 Русский",
    "page.title.accountApprove": "Account approve",
    "form.tfa.codeSended": "Code is sent to ",
    "form.tfa.resend": "Resend 2fa code",
    "form.tfa.wrongNumber": "Wrong number?",
    "sidebar.editProfile": "Edit profile",
    "sidebar.shortcuts": "Shortcuts",
    "sidebar.companyList": "My payment bots",
    "sidebar.botSetting": "Bot settings",
    "sidebar.subscribers": "Subscribers",
    "sidebar.analytics": "Analytics",
    "sidebar.invoices": "Invoices",
    "sidebar.textMessages": "Text messages",
    "sidebar.purchaseReturns": "Purchase returns",
    "ui.botCreate": "Create new Bot",
    "page.AccountNoBotSelected": "Create your first payment bot to start!",
    "page.AccountProfile": "Profile",
    "page.signIn": "Sign in",
    "footer.userSupport": "Customer support",
    "footer.userAgreement": "Legal agreement",
    "ui.save": "Save",
    "btn.sendOtpCode": "Send 2fa code",
    "ui.logout": "Logout",
    "ui.success": "Success 🥳",
    "form.tgApiTokenLabel": "Telegram bot Api token",
    "form.tgApiTokenPlaceholder": "Token",
    "form.create": "Create bot",
    "form.createBot.tips.title": "Where to get API token?",
    "form.createBot.tips.desc": "First create a bot in Telegram. You can do it by writing to BotFather (https://t.me/botfather). After bot creation you will receive an API token from Telegram.",
    "page.botsNew": "Create your first payment bot to start!",
    "form.update": "Update",
    "bot.DeleteTitle": "Danger zone",
    "form.botDelete.tips.title": "Remove bot?",
    "form.botDelete.tips.desc": "Be careful! If you delete the bot - you will lose access to it's analytics, and will not be able to download client database.",
    "form.botDelete.action": "Delete",
    "bot.status.incorrectApiToken": "Incorrect Api Token",
    "bot.status.incompleteBot": "Incomplete Bot",
    "bot.status.incompleteAccount": "Incomplete Account",
    "bot.status.operational": "Operational",
    "bot.status.undefined": "Checking bot status ...",
    "page.botsEdit": "Edit bot",
    "bot.invoices": "Invoices",
    "bot.invoicesShortDesc": "Who can create",
    "bot.managers": "Sales managers",
    "bot.managersShortDesc": "Who can send invoices to clients",
    "bot.managers.add": "Invite Sales Manager",
    "bot.managers.addPlaceholder": "Specify Telegram username to invite",
    "account.alerts.incompleteAccount.message": "Please complete you account not to lose access to platform. We need your phone number, email, and password.",
    "account.alerts.incompleteAccount.linkUrl": "/account/profile",
    "account.alerts.incompleteAccount.linkText": "Edit my account",
    "ui.noNotifications": "No notifications",
    "page.support": "Support",
    "form.firstName.placeholder": "First name",
    "form.firstName.label": "First name",
    "form.lastName.placeholder": "Last name",
    "form.lastName.label": "Last name",
    "form.companyName.placeholder": "Company name",
    "form.companyName.label": "Company name",
    "form.description.placeholder": "Description",
    "form.description.label": "Description",
    "form.sendRequest": "Send Request",
    "form.addManager": "Add manager",
    "ui.contacts": "Contacts",
    "error.emptyFields": "Check empty fields, please ...",
    "ui.baseQuestions": "Base questions",
    "ui.hotline": "Hotline",
    "form.startDate": "Start date",
    "form.endDate": "End date",
    "ui.summary": "Summary",
    "ui.totalRevenue": "Total Revenue",
    "ui.manualInvoices": "Manual Invoices",
    "ui.predefinedProducts": "Predefined Products",
    "ui.subscriptions": "Subscriptions",
    "ui.perfomancePerManager": "Perfomance Per Manager",
    "ui.username": "Username",
    "ui.invoicesGenerated": "Invoices generated",
    "ui.invoicesOpened": "Invoices opened",
    "ui.invoicesPaid": "Invoices paid",
    "ui.revenueGenerated": "Revenue generated",
    "ui.invoicesFromPlatform": "Invoices from platform",
    "ui.clientDatabase": "Client database and order history",
    "ui.downloadCSV": "Download in CSV format",
    "ui.uuid": "id",
    "ui.periodInvoice": "Period",
    "ui.startDate": "Start date",
    "ui.manualInvoicesRevenue": "Manual invoices revenue",
    "ui.predefinedProductsRevenue": "Predefined products revenue",
    "ui.endDate": "End date",
    "ui.subscriptionProductsRevenue": "Subscription products revenue",
    "ui.platformFee": "Platform fee",
    "page.botsAnalytics": "Bot Analytics",
    "ui.status": "Status",
    "form.taxPercentageMinIsAndMax": "Min 0, max 100",
    "form.tipMaxPercentageMinIsAndMax": "Min 0, max 500",
    "form.tipMaxPercentage": "Tip Max Percentage, %",
    "form.taxPercentage": "Tax Percentage, %",
    "bot.baseSettings": "Base settings",
    "form.botLanguage": "Language",
    "form.botLanguagePlaceholder": "Select bot language",
    "form.currency": "Currency",
    "form.currencyPlaceholder": "Select currency",
    "form.userAgreementPdf": "User agreement pdf",
    "form.userAgreementPdfPlaceholder": "Uploaded file will be shown here",
    "form.timeZoneAnalyticsListPlaceholder": "Select timezone of reports",
    "form.timeZoneAnalyticsList": "Time zone analytics",
    "form.collectEmail": "Collect email",
    "form.collectPhone": "Collect phone",
    "form.showTaxToCustomers": "Show tax to customers",
    "bot.tips": "Tips",
    "form.allowTips": "Allow tips",
    "form.tipMaxPercentagePlaceholder": "How much tips you allow to add to invoice",
    "form.taxPercentagePlaceholder": "Tax percent of total invoice sum, i.e. 20%",
    "form.taxTitle": "Tax title",
    "form.taxTitlePlaceholder": "Tax name, i.e VAT",
    "bot.telegramConnection": "Telegram connection",
    "bot.tax": "Tax in invoices",
    "bot.dataCollection": "Data collection",
    "analytics.period.last30days": "Last 30 days",
    "analytics.period.custom": "Custom period",
    "page.botsInvoices": "Invoices for bot management"
}
