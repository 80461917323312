<script>
import BaseAvatar from "./BaseAvatar.vue";
import { useUserStore } from "@/stores/user";
import api from "@/modules/api";

export default {
  name: "BotCard",
  props: {
    botId: String,
    tgUsername: String,
    firstName: String,
    active: Boolean,
  },
  components: { BaseAvatar },
  computed: {
    botIsActive: (self) => useUserStore().BOT_HAS_ACTIVE_STATUS(self.botId),
    botDetails: (self) => useUserStore().BOT_DETAILS(self.botId),
  },
  data() {
    return {
      loading: true,
    };
  },
  watch: {
    botDetails: {
      handler() {
        this.fetchDetails();
      },
      deep: true,
      immediate: true,
    },
  },
  methods: {
    async fetchDetails() {
      try {
        if (!this.botDetails?.botCard?.botId && this.botId) {
          this.loading = true;
          const { data } = await api.fetchBotsById(this.botId);
          useUserStore().setBotDetails(data?.botCard?.botId, data);
        }
      } catch (err) {
        console.error(err);
      } finally {
        setTimeout(() => {
          this.loading = false;
        }, 2 * 1000);
      }
    },
    setAsActive() {
      useUserStore().setActiveBotId(this.botId);
      if (this.$route.params.botId) {
        this.$router.replace({
          ...this.$route,
          params: {
            ...this.$route.params,
            botId: this.botId,
          },
        });
      } else {
        this.$router.push({
          name: "BotsAnalytics",
          params: { botId: this.botId },
        });
      }
    },
  },
};
</script>

<template>
  <div
    :status="botDetails?.status"
    :class="[
      $style.bot_card,
      {
        [$style.bot_active]: botIsActive,
        [$style.active]: active,
        [$style.loading]: loading,
      },
    ]"
    @click="setAsActive"
  >
    <BaseAvatar :url="botDetails?.botCard?.profilePhotoUrl" size="x-small" />
    <div :class="$style.bot_card__title">{{ firstName }}</div>
    <div
      :href="`https://t.me/${tgUsername}`"
      :class="$style.bot_card__subtitle"
      target="_blank"
    >
      @{{ tgUsername }}
    </div>
    <!-- <div v-if="botDetails?.status" :class="$style.bot_card__tooltip">
      {{ $t(`bot.status.${botDetails?.status}`) }}
    </div> -->
  </div>
</template>

<style lang="scss" module>
.bot_card {
  display: flex;
  flex-direction: column;
  align-items: start;
  justify-content: center;
  padding: 12px;
  border: 1px solid $label-color;
  border-radius: $border-radius;
  cursor: pointer;
  transition: all 400ms;
  position: relative;
  text-decoration: none;

  &::before {
    content: " ";
    position: absolute;
    top: 12px;
    right: 12px;
    width: 12px;
    height: 12px;
    border-radius: 50%;
    background: $label-color;
  }

  &.loading {
    cursor: wait;

    &::before {
      animation: scale 500ms infinite;
    }
  }

  &.bot_active {
    &::before {
      background: $primary-color;
    }
  }

  &:not(.bot_active) {
    &::before {
      background: $orange;
    }
  }

  &.active {
    border-color: $primary-color;
  }

  &:hover {
    // background: rgba($color: $primary-color, $alpha: 0.2);

    box-shadow: 0px 6px 14px -6px rgba($gray, 0.6),
      0px 10px 32px -4px rgba($gray, 0.7);
  }

  &__title {
    font-weight: 700;
    font-size: 12px;
    line-height: 14px;
    color: $text-color;
    margin: 12px 0 4px;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    max-width: 100%;
  }

  &__subtitle {
    font-weight: 400;
    font-size: 10px;
    line-height: 14px;
    color: $label-color;
    text-decoration: none;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    max-width: 100%;
  }

  &__tooltip {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    transform: translateY(-90%) scale(0);
    background: $white;
    padding: 10px;
    border: 0 solid;
    border-radius: $border-radius;
    box-shadow: 0px 6px 14px -6px rgba($gray, 0.6),
      0px 10px 32px -4px rgba($gray, 0.7);
    font-size: 12px;
    line-height: 14px;
    color: $text-color;
    transition: all 500ms;
    transform-origin: 100% 100%;
    opacity: 0;
    visibility: hidden;

    &::after {
      content: " ";
      position: absolute;
      top: 100%; /* At the bottom of the tooltip */
      right: 13px;
      margin-left: -5px;
      border-width: 5px;
      border-style: solid;
      border-color: $white transparent transparent transparent;
    }
  }

  &:hover {
    .bot_card__tooltip {
      visibility: visible;
      opacity: 1;
      transform: translateY(-90%) scale(1);
    }
  }
}
</style>
