<script>
export default {
  name: "BaseIconButton",
  emits: ["press"],
  props: {
    disabled: Boolean,
    loading: Boolean,
    notification: Boolean,
    size: {
      type: String,
      enum: ["x-small", "small"],
      default: "small",
    },
    props: {
      type: String,
      enum: ["green", "gray"],
      default: "gray",
    },
  },
  methods: {
    onClick(evt) {
      if (this.disabled || this.loading) return evt.preventDefault();
      return this.$emit("press", evt);
    },
  },
};
</script>

<template>
  <button
    v-bind="{ color, notification }"
    :class="[$style.base_icon_btn, $style[size]]"
    @click="onClick"
  >
    <slot />
  </button>
</template>

<style lang="scss" module>
@mixin hover-shadow($color) {
  background-color: rgba($color, 0.15);

  box-shadow: 0px 6px 14px -6px rgba(255, 255, 255, 0),
    0px 10px 32px -4px rgba(255, 255, 255, 0);

  svg {
    path {
      stroke: $color;
      fill: $color;
    }
  }
  &:not([disabled="true"]):not([loading="true"]) {
    &:hover,
    &:active,
    &:focus {
      background: rgba($color: $color, $alpha: 0.3);
      box-shadow: 0px 6px 14px -6px rgba($color, 0.12),
        0px 10px 32px -4px rgba($color, 0.2);
    }
  }

  &[notification="true"] {
    &::after {
      background: $yellow;
      transform: scale(1);
    }
  }
}

.base_icon_btn {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  border: 0;
  background: rgba($color: $white, $alpha: 0);
  margin: 0;
  padding: 0;
  transition: all 500ms;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  position: relative;

  &::after {
    content: " ";
    position: absolute;
    top: 4px;
    right: 4px;
    width: 8px;
    height: 8px;
    border-radius: 50%;
    transform: scale(0);
    transition: all 500ms;
  }

  &.x-small {
    width: 32px;
    height: 32px;
  }

  &.small {
    width: 40px;
    height: 40px;
  }

  &[disabled="true"],
  &:disabled {
    cursor: not-allowed;
    filter: grayscale(1);
  }

  &[loading="true"] {
    cursor: wait;
  }

  &[color="green"] {
    @include hover-shadow($primary-color);
  }

  &[color="gray"] {
    @include hover-shadow($gray);
    background: rgba($gray, 0);
  }
}
</style>
