<script>
import MenuIcon from "@/assets/graphic/menu-hamburger.svg";
import LogoSmall from "@/assets/graphic/logo-small.svg";
import NotificationsIcon from "@/assets/graphic/notification.svg";
import BaseIconButton from "./BaseIconButton.vue";
import { useUserStore } from "@/stores/user";
import BaseAvatar from "./BaseAvatar.vue";
import vClickOutside from "click-outside-vue3";
import { mapState } from "pinia";

export default {
  name: "AccountHeader",
  directives: {
    clickOutside: vClickOutside.directive,
  },
  components: {
    MenuIcon,
    BaseIconButton,
    LogoSmall,
    NotificationsIcon,
    BaseAvatar,
  },
  data() {
    return {
      notificationsActive: false,
    };
  },
  computed: {
    ...mapState(useUserStore, [
      "USER",
      "BOT_CURRENT",
      "ACTIVE_BOT_ID",
      "BOT_DETAILS",
    ]),
  },
};
</script>

<template>
  <div
    :class="$style.account_header"
    :style="{ animation: 'slide-down 800ms ease-in-out' }"
  >
    <div v-if="$route?.params?.botId" :class="$style.bot_info" class="mt2 mb2">
      <router-link
        :to="
          ACTIVE_BOT_ID
            ? { name: 'BotsAnalytics', params: { botId: ACTIVE_BOT_ID } }
            : { name: 'AccountPages' }
        "
      >
        <BaseAvatar
          :style="{ animation: 'scale 800ms ease' }"
          :url="BOT_CURRENT.profilePhotoUrl"
          size="small"
        />
      </router-link>
      <div class="flex-grow ml1">
        <div :class="$style.bot_info__name">
          <a
            :class="$style.bot_info__username"
            :href="`https://t.me/${BOT_CURRENT.tgUsername}`"
            target="_blank"
            v-text="`@${BOT_CURRENT.tgUsername}`"
          />
        </div>
        <div :class="$style.bot_info__name">
          <span
            :class="[
              $style.bot_info__status,
              {
                [$style.bot_info__status__error]:
                  BOT_DETAILS(ACTIVE_BOT_ID).status !== 'operational',
              },
            ]"
          >
            {{ $t(`bot.status.${BOT_DETAILS(ACTIVE_BOT_ID).status}`) }}
          </span>
        </div>
      </div>
    </div>
    <logo-small
      v-if="!$route?.params?.botId"
      :style="{ animation: 'scale 300ms ease-in-out' }"
      height="32"
      width="32"
    />
    <div :class="$style.account_header__title" class="pr4">
      <span v-if="$route?.params?.botId" :class="$style.account_header__bot">
        <b
          >{{ BOT_CURRENT.firstName }}
          {{ BOT_CURRENT.lastName || "" }}
        </b>
      </span>
      <span v-else>
        <b>{{ $t($route?.meta?.name || "page title") }}</b>
      </span>
    </div>
    <div
      v-if="USER && USER.alerts"
      v-click-outside="() => (notificationsActive = false)"
      :class="$style.account_header__notifications"
      class="mr1"
    >
      <base-icon-button
        :style="{ animation: 'scale 600ms ease-in-out' }"
        color="green"
        size="small"
        :notification="USER.alerts.length > 0"
        @click="notificationsActive = true"
      >
        <notifications-icon />
      </base-icon-button>
      <transition name="slide-up">
        <div
          v-if="notificationsActive"
          :class="$style.account_header__notifications__body"
        >
          <div
            v-if="USER.alerts.length == 0"
            :class="$style.account_header__notifications__empty"
          >
            {{ $t("ui.noNotifications") }}
          </div>
          <template v-else>
            <template v-for="alert in USER.alerts" :key="alert.linkUrl">
              <a
                :href="$t(alert.linkUrl)"
                :class="$style.account_header__notifications__item"
              >
                {{ $t(alert.message) }}
                <span>
                  {{ $t(alert.linkText) }}
                </span>
              </a>
            </template>
          </template>
        </div>
      </transition>
    </div>
    <base-icon-button
      v-if="USER"
      :style="{ animation: 'scale 500ms ease-in-out' }"
      :class="$style.account_header__menu"
      color="gray"
      size="small"
      @press="$emit('openSidebar')"
    >
      <menu-icon />
    </base-icon-button>
  </div>
</template>

<style lang="scss" module>
.account_header {
  height: 82px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 32px;
  position: sticky;
  z-index: 20;

  &::after {
    content: " ";
    background: $primary-color;
    height: 2px;
    width: calc(100% - 64px);
    margin: 0 auto;
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    border-radius: 1px;
  }

  &__title {
    flex: 1 1 auto;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0 15px;
    max-width: calc(100% - 130px);
    font-size: 16px;
  }

  &__menu {
    display: none;
  }

  @media (max-width: 1024px) {
    height: 62px;
    padding: 0 16px;

    &__title {
      max-width: calc(100% - 160px);
      font-size: 13px;
    }

    &::after {
      width: calc(100% - 32px);
    }

    &__menu {
      display: flex;
    }
  }

  &__notifications {
    position: relative;

    &__body {
      position: absolute;
      top: 100%;
      right: 0;
      background: #ffffff;
      box-shadow: 0px 4px 32px rgba(0, 0, 0, 0.06);
      border-radius: 4px;
      width: 400px;
      min-height: 90px;
      z-index: 9;
      overflow-x: hidden;
      overflow-y: auto;
      display: flex;
      flex-direction: column;
      z-index: 10;

      @media (max-width: 640px) {
        width: calc(100vw - 85px);
      }
    }

    &__empty {
      font-size: 12px;
      color: $gray;
      display: flex;
      align-items: center;
      justify-content: center;
      flex: 1;
    }

    &__item {
      padding: 10px;
      font-size: 12px;
      color: $gray;
      cursor: pointer;
      transition: all 500ms ease-in-out;
      text-decoration: none;

      &:hover {
        background: rgba($color: $gray, $alpha: 0.1);
      }

      span {
        display: block;
        font-weight: 500;
        color: $text-color-2;
      }
    }
  }

  .bot_info {
    display: flex;
    flex-direction: row;
    align-items: center;

    &__username {
      font-size: 12px;
      line-height: 14px;
      color: $gray;
    }

    &__name {
      font-weight: 700;
      font-size: 12px;
      line-height: 14px;
      color: $text-color;
    }

    &__status {
      font-size: 12px;
      line-height: 14px;
      color: $text-color;
      transition: all 200ms;

      &__error {
        color: $red;
      }
    }
  }
}
</style>
