export const LOCALE_KEY = "__user-locale";
export const LOCALES_LIST = ["fr", "uk", "en", "ru"];
export const AUTH_TOKENS_KEY = "__user-tokens";
export const USER_COUNTRY_KEY = "__user-country";
export const USER_INFO_KEY = "__user-info";
export const USER_ACTIVE_BOT_ID_KEY = "__user-active-bot";
export const AUTH_TOKENS_HEADER = "x-api-key";
export const API_URL =
  import.meta.env.VITE_APP_API_URL || "https://api-test.platibotu.com";
export const SENTRY_URL = import.meta.env.VITE_APP_SENTRY_URL || null;
export const BOT_USERNAME =
  import.meta.env.VITE_APP_TG_BOT_LOGIN || "PlatiBotuBot";
export const APP_NAME = import.meta.env.VITE_APP_NAME || "PlatiBotu";

export const CONTACTS = {
  phone: import.meta.env.VITE_APP_PHONE,
  mail: import.meta.env.VITE_APP_MAIL,
};
