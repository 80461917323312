<script>
import IconLoading from "@/assets/graphic/loading.svg";

export default {
  name: "BaseButton",
  components: {
    IconLoading,
  },
  props: {
    disabled: Boolean,
    loading: Boolean,
    small: Boolean,
    active: Boolean,
    textGrow: Boolean,
    color: {
      type: String,
      enum: [
        "green",
        "violet",
        "blue",
        "gray",
        "red",
        "orange",
        "green-mint",
        "transparent",
      ],
      default: "green",
    },
    href: { type: [String, Object], default: "" },
    target: { type: String, default: "" },
  },
  emits: ["press"],
  computed: {
    tag() {
      let name = "";
      const { href = "" } = this;
      switch (true) {
        case new RegExp(/http|https/gi).test(href):
          name = "a";
          break;
        case href === "":
          name = "button";
          break;
        case href !== "":
          name = "router-link";
          break;
      }
      return name || "button";
    },
  },
  methods: {
    onClick(evt) {
      if (this.disabled || this.loading) return evt.preventDefault();
      return this.$emit("press", evt);
    },
  },
};
</script>

<template>
  <component
    :is="tag"
    :class="[$style.base_btn, { [$style.small]: small }]"
    v-bind="{ disabled, loading, href, to: href, target, color }"
    @click="onClick"
  >
    <span v-if="$slots['icon-left'] || loading" :class="$style.base_btn__icon">
      <slot v-if="!loading" name="icon-left" />
      <icon-loading
        v-if="loading"
        :class="$style.base_btn__loader"
        :style="{ animation: 'icon-loading 2s infinite' }"
        width="32"
        height="32"
        class="mr2"
      />
    </span>
    <span :class="[$style.base_btn__text, { 'flex-grow': textGrow }]">
      <slot />
    </span>
    <transition name="slide-up">
      <span v-if="active" :class="$style.base_btn__active_marker" />
    </transition>
  </component>
</template>

<style lang="scss" module>
@mixin hover-shadow($color) {
  background: $color;
  box-shadow: 0px 6px 14px -6px rgba(255, 255, 255, 0),
    0px 10px 32px -4px rgba(255, 255, 255, 0);
  &:not([disabled="true"]):not([loading="true"]) {
    &:hover,
    &:active,
    &:focus {
      box-shadow: 0px 6px 14px -6px rgba($color, 0.6),
        0px 10px 32px -4px rgba($color, 0.7);
    }
  }
}

.base_btn {
  background: $primary-color;
  border-radius: $border-radius;
  font-weight: 600;
  font-size: 20px;
  line-height: 20px;
  letter-spacing: -0.32px;
  color: $white;
  min-height: 60px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  border: 0;
  padding: 0 15px;
  transition: all 400ms ease-in-out;
  max-width: 100%;
  text-decoration: none;
  position: relative;

  &__text {
    max-width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  &__icon {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  &__active_marker {
    width: 10px;
    height: 10px;
    border-radius: 50%;
    background-color: $white;
    position: absolute;
    right: 10px;
    top: calc(50% - 5px);
  }

  &.small {
    min-height: 40px;
    font-size: 12px;
    line-height: 14px;
  }

  &[disabled="true"],
  &:disabled {
    cursor: not-allowed;
    filter: grayscale(1);
  }

  &[loading="true"] {
    cursor: wait;
  }

  &[color="green"] {
    @include hover-shadow($primary-color);
  }

  &[color="violet"] {
    @include hover-shadow($violet);
  }

  &[color="blue"] {
    @include hover-shadow($blue);
  }

  &[color="gray"] {
    @include hover-shadow($gray);
  }

  &[color="red"] {
    @include hover-shadow($red);
  }

  &[color="orange"] {
    @include hover-shadow($orange);
  }

  &[color="green-mint"] {
    @include hover-shadow($green-mint);
  }

  &[color="transparent"] {
    color: $gray;
    background: rgba($color: $gray, $alpha: 0);
    &:not([disabled="true"]):not([loading="true"]) {
      &:hover,
      &:active,
      &:focus {
        background: rgba($color: $gray, $alpha: 0.15);
      }
    }

    .base_btn__active_marker {
      background-color: $gray;
    }
  }
}
</style>
