<script>
import { loadLocaleMessages } from "@/modules/i18n";
import { LOCALE_KEY } from "@/modules/config";
import { LOCALES_LIST } from "../modules/config";

export default {
  name: "LangSelector",
  computed: {
    locales: (self) => {
      // make russians always last lang
      const items = [...LOCALES_LIST];
      self.$i18n.availableLocales.map((e) => {
        if (items.indexOf(e) >= 0) {
          return;
        }
        items.splice(items.length - 1, 0, e);
      });
      return items;
    },
  },
  methods: {
    loadLocaleMessages(key) {
      loadLocaleMessages(key);
      this.$i18n.locale = key;
      localStorage.setItem(LOCALE_KEY, key);
    },
  },
};
</script>

<template>
  <div :class="$style.footer_menu">
    <!-- <div :class="$style.footer_menu__title" v-text="$t('ui.language')" /> -->
    <div :class="$style.footer_menu__items">
      <a
        v-for="key in locales"
        :key="key"
        :class="{ [$style.active]: key === $i18n.locale }"
        href="#"
        @click="loadLocaleMessages(key)"
        >{{ $t(`locale.name.${key}`) }}</a
      >
    </div>
    <!-- $t("locale.name.fr") -->
    <!-- $t("locale.name.en") -->
    <!-- $t("locale.name.uk") -->
    <!-- $t("locale.name.ru") -->
    <div :class="$style.footer_menu__items">
      <router-link to="/support">{{ $t("footer.userSupport") }}</router-link>
      <a href="#" target="blank">{{ $t("footer.userAgreement") }}</a>
    </div>
  </div>
</template>

<style lang="scss" module>
@import "@/assets/footer-menu.scss";
</style>
