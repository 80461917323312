import { createRouter, createWebHistory } from "vue-router";
import Account from "@/components/layout/Account.vue";
import Auth from "@/components/layout/Auth.vue";
import api from "@/modules/api";

const accountMiddleware = (_, __, next) => {
  if (api.tokens.token) {
    return next();
  }
  return next("/");
};

const authMiddleware = (_, __, next) => {
  if (api.tokens.token) {
    return next("/account");
  }
  return next();
};

const router = createRouter({
  history: createWebHistory(import.meta.env.BASE_URL),
  scrollBehavior(to, from, savedPosition) {
    to;
    from;
    savedPosition;
    // always scroll to top
    return { top: 0 };
  },
  routes: [
    {
      path: "/",
      name: "Index",
      redirect: "/sign-in/telegram",
    },
    {
      path: "/logout",
      name: "Logout",
      beforeEnter: (_, __, next) => {
        api.logout(next("/"));
      },
    },
    {
      path: "/support",
      name: "Support",
      redirect: "/support/create-request",
      component: Account,
      children: [
        {
          path: "create-request",
          name: "SupportPage",
          component: () => import("@/views/SupportPage.vue"),
          meta: {
            name: "page.support", // $t("page.support")
          },
        },
      ],
    },
    {
      path: "/sign-in",
      name: "SignInTelegram",
      redirect: "/sign-in/telegram",
      beforeEnter: authMiddleware,
      component: Auth,
      children: [
        {
          path: "telegram",
          meta: {
            name: "page.signIn", // $t("page.signIn")
          },
          component: () => import("@/views/SignInTelegram.vue"),
        },
        {
          path: "email",
          name: "SignInMail",
          meta: {
            name: "page.signIn", // $t("page.signIn")
          },
          component: () => import("@/views/SignInMail.vue"),
        },
        {
          path: "phone",
          name: "SignInPhone",
          meta: {
            name: "page.signIn", // $t("page.signIn")
          },
          component: () => import("@/views/SignInPhone.vue"),
        },
      ],
    },
    {
      path: "/account",
      name: "AccountPages",
      redirect: `/account/bots/main/analytics`,
      beforeEnter: accountMiddleware,
      component: Account,
      children: [
        {
          path: "profile",
          name: "AccountProfile",
          meta: {
            name: "page.AccountProfile", // $t("page.AccountProfile")
          },
          component: () => import("@/views/AccountProfile.vue"),
        },
        {
          path: "bots/create",
          name: "BotsCreate",
          meta: {
            name: "page.botsNew", // $t("page.botsNew")
          },
          component: () => import("@/views/BotsEditor.vue"),
        },
        {
          path: "bots/:botId/edit",
          name: "BotsEditor",
          meta: {
            name: "page.botsEdit", // $t("page.botsEdit")
            checkBotId: true,
          },
          component: () => import("@/views/BotsEditor.vue"),
        },
        {
          path: "bots/:botId/analytics",
          name: "BotsAnalytics",
          meta: {
            name: "page.botsAnalytics", // $t("page.botsAnalytics")
            checkBotId: true,
          },
          component: () => import("@/views/BotsAnalytics.vue"),
        },
        {
          path: "bots/:botId/invoices",
          name: "BotsInvoices",
          meta: {
            name: "page.botsInvoices", // $t("page.botsAnalytics")
            checkBotId: true,
          },
          component: () => import("@/views/BotsInvoices.vue"),
        },
      ],
    },
  ],
});

export default router;
