import { createApp } from "vue";
import { createPinia } from "pinia";

import App from "@/App.vue";
import router from "@/router";
import BaseButton from "@/components/BaseButton.vue";
import BaseInput from "@/components/BaseInput.vue";
import { setupI18n, getUserLocale } from "./modules/i18n";
import en from "@/locales/en.json";
import apiClient from "@/modules/api";
import * as Sentry from "@sentry/vue";
import { BrowserTracing } from "@sentry/tracing";
import { SENTRY_URL } from "@/modules/config";
import maska from "maska";

import Toast from "vue-toastification";
// Import the CSS or use your own!
import "vue-toastification/dist/index.css";
import "@/assets/index.scss";

import VueNumber from "vue-number-animation";

const app = createApp(App);

const pinia = createPinia();
app.use(pinia);

app.component("BaseButton", BaseButton);
app.component("BaseInput", BaseInput);

const i18n = setupI18n({
  globalInjection: true,
  legacy: false,
  locale: "en",
  fallbackLocale: "en",
  messages: {
    fr: {},
    uk: {},
    en,
    ru: {},
  },
});
getUserLocale(i18n);

if (SENTRY_URL) {
  Sentry.init({
    app,
    dsn: SENTRY_URL,
    integrations: [
      new BrowserTracing({
        routingInstrumentation: Sentry.vueRouterInstrumentation(router),
        tracingOrigins: ["localhost", "my-site-url.com", /^\//],
      }),
    ],
    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    // We recommend adjusting this value in production
    tracesSampleRate: 1.0,
  });
}

app.config.globalProperties.$apiClient = apiClient;

app
  .use(router)
  .use(i18n)
  .use(VueNumber)
  .use(maska, {
    placeholders: { "*": /[a-zA-Z0-9_-]/ },
  })
  .use(Toast, {
    maxToasts: 20,
    newestOnTop: true,
    // rtl: true,
  })
  .mount("#app");
