<script>
export default {
  name: "BaseAvatar",
  props: {
    url: String,
    size: {
      type: String,
      enum: ["x-small", "small", "medium", "large"],
      default: "medium",
    },
  },
};
</script>

<template>
  <div
    :class="[$style.base_avatar, $style[size]]"
    :style="{
      backgroundImage: `url(${url || '/images/profile-default.png'})`,
    }"
  ></div>
</template>

<style lang="scss" module>
.base_avatar {
  border-radius: 50%;
  background-position: center;
  background-size: cover;

  &.medium {
    width: 56px;
    height: 56px;
  }

  &.large {
    width: 62px;
    height: 62px;
  }

  &.x-small {
    width: 32px;
    height: 32px;
  }

  &.small {
    width: 40px;
    height: 40px;
  }
}
</style>
