<script>
export default {
  name: "BaseInput",
  props: {
    modelValue: String,
    code: Boolean,
    loading: Boolean,
    disabled: Boolean,
    readonly: Boolean,
    autofocus: Boolean,
    textarea: Boolean,
    required: Boolean,
    label: String,
    error: String,
    placeholder: String,
    mask: String,
    min: Number,
    max: Number,
    inputType: {
      type: String,
      enum: ["text", "password", "search", "email", "date"],
      default: "text",
    },
    name: {
      type: String,
      required: true,
    },
    pattern: { type: String, default: "" },
    maxlength: { type: Number, default: 150 },
  },
  emits: ["update:modelValue", "focus", "blur", "backspace", "change"],
  computed: {
    tag: (self) => (self.textarea ? "textarea" : "input"),
    attr: (self) => {
      const {
        readonly,
        name,
        placeholder,
        inputType,
        disabled,
        loading,
        pattern,
        autofocus,
        required,
        min,
        max,
      } = self;
      return {
        loading,
        readonly,
        name,
        placeholder,
        type: inputType,
        disabled: disabled || loading,
        pattern,
        autofocus,
        required,
        ...(inputType === "number" ? { min, max } : {}),
      };
    },
  },
  methods: {
    updateValue(event) {
      this.$emit(
        "update:modelValue",
        event.target.value
        // this.mask ? event.target.value.replace(/ /gi, "") : this.mask
      );
    },
  },
};
</script>

<template>
  <div :class="[$style.base_input, { [$style.code]: code }]">
    <label v-if="label" :for="name"
      >{{ label }} {{ required ? "*" : "" }}</label
    >
    <component
      :is="tag"
      v-bind="attr"
      v-maska="mask"
      :value="modelValue"
      @input="updateValue"
      @keyup.delete="(evt) => $emit('backspace', evt)"
      @keyup.backspace="(evt) => $emit('backspace', evt)"
      @focus="(evt) => $emit('focus', evt)"
      @blur="(evt) => $emit('blur', evt)"
      @change="$emit('change', modelValue)"
    />
    <div v-if="error" :class="$style.base_input__msg">{{ error }}</div>
  </div>
</template>

<style lang="scss" module>
.base_input {
  display: flex;
  flex-direction: column;
  padding-bottom: 12px;

  &__msg {
    font-weight: 400;
    font-size: 13px;
    line-height: 16px;
    letter-spacing: -0.078px;
    color: $error;
    padding: 6px 0;
  }

  input,
  textarea {
    background: rgba($color: $white, $alpha: 0.4);
    border: 1px solid $border-color;
    border-radius: $border-radius;
    padding: 0 24px;
    font-weight: 400;
    font-size: 20px;
    line-height: 59px;
    transition: all 400ms;
    color: $text-color;

    &::placeholder {
      color: $placeholder-color;
    }

    &:disabled {
      cursor: not-allowed;
      background: $account-sidebar-border;
    }

    &:focus {
      border-color: $primary-color;
    }

    &[loading="true"] {
      cursor: wait;
    }
  }

  textarea {
    line-height: 22px;
    padding: 24px;
    resize: none;
  }

  label {
    font-weight: 400;
    font-size: 15px;
    line-height: 20px;
    letter-spacing: -0.32px;
    color: $label-color;
    padding: 5px 0;
  }

  &.code {
    margin: 0 3px;
    input {
      padding: 0;
      width: 100%;
      line-height: 62px;
      text-align: center;
    }
  }
}
</style>
