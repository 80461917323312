import { nextTick } from "vue";
import { createI18n } from "vue-i18n";
import { LOCALE_KEY } from "./config";

let i18nGlobal = null;

export function setupI18n(options = { locale: "en", fallbackLocale: "en" }) {
  const i18n = createI18n(options);
  setI18nLanguage(i18n, options.locale);
  i18nGlobal = i18n;
  return i18n;
}

export function setI18nLanguage(i18n, locale) {
  if (i18n.mode === "legacy") {
    i18n.global.locale = locale;
  } else {
    i18n.global.locale.value = locale;
  }
  /**
   * NOTE:
   * If you need to specify the language setting for headers, such as the `fetch` API, set it here.
   * The following is an example for axios.
   *
   * axios.defaults.headers.common['Accept-Language'] = locale
   */
  document.querySelector("html").setAttribute("lang", locale);
}

export async function loadLocaleMessages(locale) {
  // load locale messages with dynamic import
  const messages = await import(
    /* webpackChunkName: "locale-[request]" */ `../locales/${locale}.json`
  );
  // set locale and locale message
  i18nGlobal.global.setLocaleMessage(locale, messages.default);

  return nextTick();
}

export async function getUserLocale() {
  let locale = localStorage.getItem(LOCALE_KEY);
  if (locale) {
    return loadLocaleMessages(locale);
  }
  locale = navigator.language.slice(0, 2);
  if (locale && i18nGlobal.global.availableLocales.indexOf(locale) >= 0) {
    return loadLocaleMessages(locale);
  }

  return loadLocaleMessages("en");
}
